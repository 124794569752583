











































import FilterCard from "@/components/filter/FilterCard.vue";
import AffiliateCreateDialog from "@/components/thg/AffiliateCreateDialog.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { IAffiliateType } from "@/lib/interfaces/affiliate/IAffiliateType";
import { affiliateTypeMap } from "@/lib/utility/affiliateTypeMap";
import { simpleDate } from "@/lib/utility/date-helper";
import { getDateFilterList } from "@/lib/utility/filter";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { AffiliatePortalModule } from "@/store/modules/affiliate.portal.store";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { PartnerModule } from "@/store/modules/partner";

@Component({ components: { FilterCard, AffiliateCreateDialog } })
export default class AffiliateListCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  search = "";

  affiliateTypeMap = affiliateTypeMap;

  affiliateType(affiliateType: any): IAffiliateType {
    return (
      this.affiliateTypeMap.get(affiliateType) || {
        type: "components.thg.affiliateTypeMap.undefinedType",
        description: "components.thg.affiliateTypeMap.undefinedDescription"
      }
    );
  }

  /**
   * The no image source. From the @see AssetRepository.
   */
  get emptyDataSrc() {
    return AssetRepository.getAsset(false, AssetEnum.emptyData);
  }

  get affiliates() {
    return AffiliatePortalModule.affiliates;
  }

  isActive(item: any) {
    return item.id === AffiliatePortalModule.selectedAffiliate.id ? this.color : "";
  }

  date(item: any): string {
    return simpleDate(item.timestamp?.created);
  }

  isLoadingDetail(status: boolean) {
    this.$emit("isLoadingDetail", status);
  }

  async set(item: any) {
    this.isLoadingDetail(true);
    if (["xs", "sm"].includes(this.$vuetify.breakpoint.name)) {
      this.$router.push({ name: "AffiliateDetailView", params: { affiliateId: item.id } });
    } else {
      const affiliate = await AffiliatePortalModule.getSelectedAffiliate({
        affiliateId: item.id,
        partnerId: PartnerModule.partner.id || PartnerModule.partner._id
      });
      if (!affiliate) {
        Vue.$toast.error("Fehler beim Laden.");
      }
    }
    this.isLoadingDetail(false);
  }

  //Filter

  /**
   * The available categories visible in the filter menu
   */
  filterList = getDateFilterList("timestamp");

  items = this.affiliates;

  get filteredItems() {
    return this.items;
  }
}
