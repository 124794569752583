




















import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import { MeterReadingPaginatedModule } from "@/store/modules/meter-reading-paginated.store";
import { Component } from "vue-property-decorator";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import ThgBillingRecreationIndividualDialog from "@/components/thg/ThgBillingRecreationIndividualDialog.vue";
import ThgBatchUpdateDialog from "@/components/thg/ThgBatchUpdateDialog.vue";

@Component({
  components: {
    TheLayoutPortal,
    ThgBillingRecreationIndividualDialog,
    PaginatedTable,
    ThgBatchUpdateDialog
  },
  filters: {
    getFlagEmoji,
    simpleDate
  }
})
export default class ThgMeterReadingTable extends DarkModeHighlightMixin {
  readonly store = MeterReadingPaginatedModule;

  readonly billingType = BillingTypeEnum.CREDIT_CHARGING_STATION;

  selected: IThg[] = [];

  get headers() {
    return [
      {
        text: "Created",
        value: "timestamp.created",
        width: 100,
        type: "date"
      },
      {
        text: "Status",
        value: "status"
      },
      {
        text: "Land",
        value: "countryCode",
        width: 90
      },
      {
        text: "Jahr",
        value: "year"
      },
      {
        text: "Bonus",
        value: "payoutConfiguration.revenue",
        width: 90
      },
      {
        text: "fixed",
        value: "payoutConfiguration.isFixed",
        width: 90
      },
      {
        text: "Impact Type",
        value: "impactType",
        width: 90
      },
      {
        text: "Impact Factor",
        value: "impactFactor",
        align: "start"
      },
      { text: "Id", value: "id", align: "start", width: 70 },
      { text: "Partner", align: "start", value: "partnerId", width: 70 },
      { text: "User", align: "start", value: "userId", width: 70 }
    ];
  }

  goToDetail(item: IThg) {
    new GoToHelper(this.$router).goToMeterReadingDetail(item.partnerId, item.id, true);
  }
}
