var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "span",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.CREATE,
            a: _vm.ResourceEnum.MESSAGE,
            field: _vm.partnerId
          }
        },
        [
          _vm.isFeatureActive && !_vm.large
            ? _c(
                "v-btn",
                {
                  attrs: {
                    icon: "",
                    loading: _vm.externalLoading
                  },
                  on: {
                    click: _vm.show
                  }
                },
                [_c("v-icon", [_vm._v("mdi-email-outline")])],
                1
              )
            : _vm.isFeatureActive
            ? _c(
                "v-btn",
                {
                  staticClass: "ma-1",
                  attrs: {
                    text: "",
                    block: "",
                    outlined: "",
                    large: "",
                    loading: _vm.externalLoading
                  },
                  on: {
                    click: _vm.show
                  }
                },
                [_vm._v(" Mail ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          key:
            _vm.partnerId ||
            "" + _vm.from + _vm.to + JSON.stringify(_vm.context),
          attrs: {
            isDialogActive: _vm.isDialogActive,
            fullscreen: true,
            title: _vm.$t("components.template.dialog.preview.title"),
            leftText: _vm.$t("components.template.dialog.preview.back"),
            rightText: _vm.$t("components.template.dialog.preview.send"),
            supressKeyboardActions: true
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            leftClick: function leftClick($event) {
              _vm.isDialogActive = false
            },
            rightClick: _vm.send
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                height: "calc(100vh - 400px)",
                overflow: "hidden"
              }
            },
            [
              _c("template-card", {
                key: _vm.selectedPartner
                  ? _vm.selectedPartner.id
                  : "selectedPartner.id",
                ref: "templateCard",
                attrs: {
                  isCustomSenderSelection: true,
                  from: _vm.selectedPartner ? [_vm.selectedPartner] : [],
                  context: _vm.loadedContext,
                  to: _vm.to,
                  refs: _vm.refs
                },
                on: {
                  end: function end($event) {
                    return _vm.$emit("end")
                  },
                  sent: _vm.sent
                },
                scopedSlots: _vm._u([
                  {
                    key: "senderSelection",
                    fn: function fn() {
                      return [
                        _c("v-autocomplete", {
                          attrs: {
                            loading: _vm.loading,
                            items: _vm.partners,
                            "item-text": function itemText(i) {
                              return i.companyName
                            },
                            filter: _vm.filterPartners,
                            label: _vm.$t(
                              "components.template.dialog.preview.sender"
                            ),
                            "cache-items": "",
                            outlined: "",
                            "return-object": "",
                            dense: "",
                            solo: "",
                            flat: ""
                          },
                          on: {
                            change: _vm.setSelectedPartnerToContext
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "item",
                              fn: function fn(_ref) {
                                var item = _ref.item
                                return [
                                  _c(
                                    "v-list-item-group",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: {
                                            "two-line": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  _vm._s(item.companyName) + " "
                                                )
                                              ]),
                                              _c("v-list-item-subtitle", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getAddressText(item)
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.selectedPartner,
                            callback: function callback($$v) {
                              _vm.selectedPartner = $$v
                            },
                            expression: "selectedPartner"
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }