
































import { simpleDate } from "@/lib/utility/date-helper";
import { $t } from "@/lib/utility/t";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import { IUser } from "@/models/user.entity";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import ThgAdminUpdatePayoutConfigurationDialog from "./ThgAdminUpdatePayoutConfigurationDialog.vue";

@Component({
  components: { Card, ThgAdminUpdatePayoutConfigurationDialog },
  filters: { simpleDate }
})
export default class ThgQuoteDetailInformationContractCard extends DarkModeHighlightMixin {
  @Prop({ default: undefined })
  thg!: IThg;

  @Prop({ default: undefined })
  user!: IUser;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: false })
  isMeterreading!: boolean;

  get payout() {
    if (this.thg.payoutConfiguration.isFixed) {
      return (
        this.$t("components.thg.ThgQuoteDetailInformationContractCard.fix") +
        this.thg?.payoutConfiguration?.revenue?.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR"
        })
      );
    }
    return (
      this.$t("components.thg.ThgQuoteDetailInformationContractCard.atLeast") +
      this.thg?.payoutConfiguration?.revenue?.toLocaleString("de-DE", {
        style: "currency",
        currency: "EUR"
      })
    );
  }

  get promotion() {
    const value = this.thg?.promotionConfiguration?.value?.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR"
    });

    const type = $t(`common.PromotionTypeEnum.${this.thg?.promotionConfiguration?.type}`);

    return `${type} ${value}`;
  }

  save(updateThg: IThg) {
    this.$emit("save", updateThg);
  }

  toPartnerDetailView() {
    const roueData = this.$router.resolve({
      name: "PartnerDetailView",
      params: { partnerId: this.thg.partnerId }
    });

    window.open(roueData.href, "_blank");
  }
}
