












































































import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { BillingBatchModule } from "@/store/modules/billing-batch.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import TemplateCard from "./TemplateCard.vue";
import TemplateDialog from "./TemplateDialog.vue";
import { IReference } from "@/models/reference.entity";

/**
 * Used to send a mail
 *
 * first a selection of templates is shown that can be used in the current context
 * define context by passing it in the context prop or passing a loadContext callback function that loads the context
 */
@Component({ components: { PermissionMixin, TemplateCard, ConfirmActionDialog } })
export default class TemplateDialogPartnerSelection extends mixins(TemplateDialog, PermissionMixin) {
  @Prop()
  context?: ITemplateContext;

  @Prop()
  loadContext?: () => Promise<ITemplateContext>;

  @Prop()
  to?: string[];

  @Prop({ default: false })
  externalLoading?: boolean;

  @Prop({ default: false })
  large?: boolean;

  @Prop()
  refs?: IReference[];

  loading = true;

  loadedContext: ITemplateContext = {};

  selectedPartner: PartnerEntity | null = null;

  show() {
    this.selectedPartner = this.partners.find(p => p.id === BillingBatchModule.selectedPartnerId) ?? null;
    this.isDialogActive = true;
    this.setSelectedPartnerToContext();
  }

  filterPartners(item: PartnerEntity, queryText: string) {
    return JSON.stringify(item)
      .toLocaleLowerCase()
      .includes(queryText.toLocaleLowerCase());
  }

  getAddressText(partner: PartnerEntity) {
    const zip = partner?.address?.zip || "";
    const city = partner?.address?.city || "";
    const street = partner?.address?.street || "";

    return `${zip} ${city}, ${street}`;
  }

  get partners(): PartnerEntity[] {
    return PartnerModule.partners;
  }

  async mounted() {
    try {
      this.loading = true;
      if (!this.context && this.loadContext) {
        this.loadedContext = await this.loadContext();
      } else if (this.context) {
        this.loadedContext = this.context;
      }

      if (!this.partners.length) {
        await PartnerModule.getPartners();
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
    }
  }

  setSelectedPartnerToContext() {
    if (!this.selectedPartner) {
      return;
    }
    BillingBatchModule.setSelectedPartnerId(this.selectedPartner.id);
    this.loadedContext = { ...this.context, partner: this.selectedPartner };
    (this.$refs.templateCard as TemplateCard).selectedSender = this.selectedPartner.companyName;
  }
}
