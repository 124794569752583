var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c("paginated-table", {
        attrs: {
          title: _vm.$t("thg.ThgMeterReadingTable.title"),
          headers: _vm.headers,
          store: _vm.store,
          showSelect: true,
          selectedItems: _vm.selected
        },
        on: {
          "update:selectedItems": function updateSelectedItems($event) {
            _vm.selected = $event
          },
          "update:selected-items": function updateSelectedItems($event) {
            _vm.selected = $event
          },
          "click:row": _vm.goToDetail
        },
        scopedSlots: _vm._u([
          {
            key: "headerActions",
            fn: function fn() {
              return [
                _c("thg-billing-recreation-individual-dialog", {
                  staticClass: "ml-1 mr-1",
                  attrs: {
                    thgs: _vm.selected,
                    billingType: _vm.billingType
                  }
                }),
                _c("thg-batch-update-dialog", {
                  staticClass: "ml-1 mr-1",
                  attrs: {
                    thgs: _vm.selected,
                    isMeterReading: true
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }