









































































































































import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { HttpException, InternalServerErrorException } from "@/lib/exceptions/http";
import { requiredRule } from "@/lib/rules/requiredRule";
import { handleError } from "@/lib/utility/handleError";
import { PartnerEntity } from "@/models/partnerEntity";
import { ThgPriceViewModelGen } from "@/services/thg/v1/data-contracts";
import { CreatePriceDto } from "@/store/models/thg/create-price.dto";
import { PartnerModule } from "@/store/modules/partner";
import { PriceModule } from "@/store/modules/price.store";
import { Component, Vue } from "vue-property-decorator";
import * as XLSX from "xlsx";

/**
 * Starts operation in backend to credit-bill thgs automatically
 */
@Component({ components: { ConfirmActionDialog } })
export default class ThgPriceImport extends Vue {
  dialog = false;

  activeWindow = 0;
  inputFile: File | undefined = undefined;
  importWorkBook: any = undefined;
  isFileLoaded = false;
  content: ArrayBuffer | undefined | string | null = undefined;
  worksheetAsJson = [];
  priceDtos: CreatePriceDto[] = [];

  importColumnMap = {
    partnerId: "",
    value: "",
    isFixed: "",
    commission: "",
    year: ""
  };
  progressCounter = 0;
  isDialogLoading = false;

  isColumnMapValid = false;

  get requiredRule() {
    return [requiredRule()];
  }

  importError: { progressCounter: number; text: string }[] = [];
  successDto: CreatePriceDto[] = [];
  errorDto: CreatePriceDto[] = [];

  get headers() {
    return [
      { text: "Nr.", value: "progressCounter" },
      { text: "Fehler", value: "text" }
    ];
  }

  async createPrices() {
    this.isDialogLoading = true;
    this.progressCounter = 0;
    this.activeWindow = 3;

    try {
      for (const price of this.priceDtos) {
        if (!price.partner) {
          this.importError.push({ progressCounter: this.progressCounter, text: "Partner not set" });
          this.errorDto.push(price);
          this.progressCounter++;
          continue;
        }

        try {
          await PriceModule.create({ partnerId: price.partner.id, data: price.toDto() });
          this.successDto.push(price);
        } catch (error) {
          if (error instanceof HttpException) {
            this.errorDto.push(price);
            if (error instanceof InternalServerErrorException) {
              this.importError.push({ progressCounter: this.progressCounter, text: "InternalServerErrorException" });
            } else {
              this.importError.push({ progressCounter: this.progressCounter, text: error.message });
            }
          }
        }

        this.progressCounter++;
      }
    } catch (error) {
      handleError(error);
    } finally {
      this.$emit("finished", true);
      this.isDialogLoading = false;
    }
  }

  createPriceDto() {
    this.priceDtos = [];
    for (const priceData of this.worksheetAsJson) {
      const priceDto = new CreatePriceDto(undefined, this.getPartner(priceData[this.importColumnMap.partnerId]));
      priceDto.value = priceData[this.importColumnMap.value];
      priceDto.commission = priceData[this.importColumnMap.commission];
      priceDto.isFixed = priceData[this.importColumnMap.isFixed];
      priceDto.year = priceData[this.importColumnMap.year];
      this.priceDtos.push(priceDto);
    }
    this.activeWindow = 2;
  }

  get prices(): ThgPriceViewModelGen[] {
    return PriceModule.paginationList;
  }

  get partners(): PartnerEntity[] {
    return PartnerModule.partners;
  }

  closeAndReset() {
    this.dialog = false;
    this.reset();
  }

  reset() {
    this.progressCounter = 0;
    this.activeWindow = 0;
    this.inputFile = undefined;
    this.importWorkBook = undefined;
    this.isFileLoaded = false;
    this.content = undefined;
    this.worksheetAsJson = [];
    this.priceDtos = [];
    this.importError = [];
    this.successDto = [];
    this.errorDto = [];
    this.importColumnMap = {
      partnerId: "",
      value: "",
      isFixed: "",
      commission: "",
      year: ""
    };
  }

  get keys() {
    if (this.worksheetAsJson.length) {
      return Object.getOwnPropertyNames(this.worksheetAsJson[0]);
    } else {
      return [];
    }
  }

  async importFileAsExcel() {
    if (this.inputFile) {
      await this.readFile();
      this.importWorkBook = XLSX.read(this.content);
      if (this.importWorkBook) {
        const wsname = this.importWorkBook.SheetNames[0];
        this.worksheetAsJson = XLSX.utils.sheet_to_json(this.importWorkBook.Sheets[wsname], { defval: null });
        this.activeWindow = 1;
      }
    }
  }

  async readFile() {
    const reader = new FileReader();
    if (this.inputFile) {
      reader.onload = res => {
        this.content = res?.target?.result;
        this.isFileLoaded = true;
      };
      reader.onerror = err => this.$log.error(err);
      reader.readAsArrayBuffer(this.inputFile);
    }
  }

  getPartner(partnerId: string) {
    return PartnerModule.partnersMap.get(partnerId);
  }

  preview(previewColumn: string) {
    let counter = 0;
    const preview: any = [];
    for (const priceData of this.worksheetAsJson) {
      counter++;
      if (counter > 3) {
        break;
      }
      preview.push(priceData[previewColumn]);
    }

    return preview;
  }
}
