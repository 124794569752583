




















































import ThgQuoteWorkitemRegistrationCard from "@/components/thg/ThgQuoteWorkitemRegistrationCard.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component } from "vue-property-decorator";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";

@Component({
  components: {
    ThgQuoteWorkitemRegistrationCard
  }
})
export default class ThgQuoteBankingInformationDialog extends DarkModeHighlightMixin {
  isLoading = false;

  dialog = false;

  warningStatus = [ThgStatusEnum.PAYED, ThgStatusEnum.DUPLICATE, ThgStatusEnum.NO_BEV];

  get warning() {
    return this.warningStatus.includes(ThgPortalModule.selectedThg.status as any);
  }

  get amount() {
    return ThgPortalModule.amountOfCredit;
  }

  set amount(value: number) {
    ThgPortalModule.setAmountOfCredit(value);
  }

  get payment() {
    return (this.amount * this.paymentFactor) / 100;
  }

  get selectedThg() {
    return ThgPortalModule.selectedThg;
  }

  get paymentFactor(): number {
    return 100 - ThgPortalModule.selectedThg.impactFactor;
  }

  get selectedBanking() {
    return ThgPortalModule.bankingInformation;
  }

  get loading() {
    return this.isLoading;
  }

  async initialize() {
    this.isLoading = true;
    try {
      await ThgPortalModule.getBankingInformation();
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Fehler beim Laden der Bankinformationen");
    } finally {
      this.isLoading = false;
    }
  }

  close() {
    this.dialog = false;
  }
}
